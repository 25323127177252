import React from 'react';
import { FaGithub } from 'react-icons/fa';
import { FaTwitter } from 'react-icons/fa';

import './style.scss';

import gatsbyLogo from '../images/gatsby-icon.png';
import bulmaLogo from '../images/bulma-logo.png';
import Navbar from './navbar';

const Header = ({ siteTitle }) => (
	<section className="hero has-background-danger is-fullheight-with-navbar">
		<Navbar />
		<div className="hero-body">
			<div className="container center">
				<article className="media">
					<div className="media-content">
						<div className="content">
							<p className="subtitle has-text-white is-size-3">
								Twitter{' '}
								<a
									className="button is-info is-inverted"
									href="https://twitter.com/Sandeep_Saurav_"
								>
									<span className="icon">
										<FaTwitter size="fa-2x" />
									</span>
								</a>
							</p>
						</div>
					</div>
				</article>
			</div>
		</div>
	</section>
);

export default Header;
