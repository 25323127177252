import React from 'react'
// import { FaTwitter, FaGithub, FaMedium } from 'react-icons/fa'
import { StaticQuery, graphql } from 'gatsby'
import './style.scss'
// import Emoji from './emoji'

const Footer = () => (
  <StaticQuery
    query={graphql`
      query SocialQuery {
        site {
          siteMetadata {
            twitter
          }
        }
      }
    `}
    render={data => (
      <footer className='footer center has-background-danger'>
        <div className="container">
          <div className="content has-text-centered">
            <div className="soc">
              <a href="#"><i className="fa fa-facebook fa-lg" aria-hidden="true"></i></a>
              <a href="#"><i className="fa fa-twitter fa-lg" aria-hidden="true"></i></a>
            </div>
            <p>
              <strong>Copyright</strong> © 2020 Sandeep Saurav
            </p>
          </div>
        </div>
      </footer>
    )}
  />
)

export default Footer
