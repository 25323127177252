import React from 'react';
import { FaGithub } from 'react-icons/fa';

import './style.scss';
import gatsbyLogo from '../images/gatsby-icon.png';
import bulmaLogo from '../images/bulma-logo.png';
import cpiLogo from '../images/cpiml-logo.jpg';

const Navbar = () => (
	// <!-- START NAV -->

    <nav className="navbar navbar-wrapper is-fixed-top has-background-danger">
      <div className="container">
        <div className="navbar-brand">
          <a className="navbar-item has-text-white is-size-1" href="#">
          Sandeep Saurav
          </a>
          <span className="navbar-burger burger" data-target="navbarMenu">
            <span></span>
            <span></span>
            <span></span>
          </span>
        </div>
        <div id="navbarMenu" className="navbar-menu">
          <div className="navbar-end">
            <a className="navbar-item is-active is-size-4">
              Home
            </a>
            <a class="navbar-item is-size-4">
              About
            </a>
            <a className="navbar-item is-size-4">
              Team
            </a>
            <a className="navbar-item is-size-4">
              Contact
            </a>
          </div>
        </div>
      </div>
    </nav>
    // <!-- END NAV -->

	// <div className="hero-head is-hidden-mobile">
	// 	<nav className="navbar">
	// 		<div className="container">
	// 			<div id="navbarMenuHeroA" className="navbar-menu">
	// 				<div className="navbar-end">
	// 					<span className="navbar-item">
	// 						<a
	// 							className="button is-danger is-inverted"
	// 							href="https://github.com/amandeepmittal/gatsby-bulma-quickstart"
	// 						>
	// 							<span className="icon">
	// 								<FaGithub size="fa-2x" />
	// 							</span>
	// 							<span>Download</span>
	// 						</a>
	// 					</span>
	// 				</div>
	// 			</div>
	// 		</div>
	// 	</nav>
	// </div>
);

export default Navbar;
